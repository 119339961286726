import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { ConfirmDenyModal } from "components/modal/confirmDenyModal";
import { Status } from "pages/pupil/components/tableRow";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useCancelShopItemOrderMutation } from "../models/cancelShopItemOrder";
import { useCompleteShopItemOrderMutation } from "../models/completeShopItemOrder";
import { GetOpenShopItemOrdersForAssignedShopItemQuery } from "../models/getOpenShopItemOrdersForAssignedShopItem";

interface Props {
    order: GetOpenShopItemOrdersForAssignedShopItemQuery["shopItemOrders"][0];
    showClassNumber: boolean;
}

export const ShopItemOrderHistoryModalTableRow = (props: Props) => {
    const { t } = useTranslation();
    const { order, showClassNumber } = props;

    const status = order.canceledAt ? Status.CANCELED : (order.deliveredAt ? Status.DELIVERED : Status.OPEN);
    const shouldHideCancelButton = (status === Status.CANCELED || order?.shopItem?.type?.type === "VOUCHER");
    const shouldHideCompleteButton = (status === Status.DELIVERED || status === Status.CANCELED);

    const [ isCompleteModalOpen, setIsCompleteModalOpen ] = useState(false);
    const [ isCancelModalOpen, setIsCancelModalOpen ] = useState(false);
    const [ cancelReason, setCancelReason ] = useState("");

    const [ completeShopItemOrder ] = useCompleteShopItemOrderMutation({ refetchQueries: ["getOpenShopItemOrdersForAssignedShopItem"] });
    const [ cancelShopItemOrder ] = useCancelShopItemOrderMutation({ refetchQueries: ["getOpenShopItemOrdersForAssignedShopItem" ] });

    const onCompleteShopItemOrder = () => {
        setIsCompleteModalOpen(true);
    };

    const onCancelShopItemOrder = () => {
        setIsCancelModalOpen(true);
    };

    const onCompleteModalConfirm = async () => {
        setIsCompleteModalOpen(false);
        if (!order || !order.id) {
            return;
        }
        completeShopItemOrder({ variables: { orderId: order.id }});
    };

    const onCompleteModalDeny = async () => {
        setIsCompleteModalOpen(false);
    };

    const onCancelModalConfirm = async () => {
        setIsCancelModalOpen(false);
        if (!order || !order.id) {
            return;
        }
        cancelShopItemOrder({ variables: { orderId: order.id, reason: cancelReason }});
        setCancelReason("");
    };

    const onCancelModalDeny = async () => {
        setIsCancelModalOpen(false);
    };

    return (
        <>
            {
                !shouldHideCompleteButton && (
                    <ConfirmDenyModal title="Weet je het zeker?" isOpened={isCompleteModalOpen} onConfirm={onCompleteModalConfirm} onDeny={onCompleteModalDeny}>
                        <Text>{t("pupilPage.confirmDenyModal.confirm.body")}</Text>
                    </ConfirmDenyModal>
                )
            }
            {
                !shouldHideCancelButton && (
                    <ConfirmDenyModal title={t("pupilPage.confirmDenyModal.deny.title")} isOpened={isCancelModalOpen} onConfirm={onCancelModalConfirm} onDeny={onCancelModalDeny}>
                        <Text>
                            <Trans i18nKey="pupilPage.confirmDenyModal.deny.body" components={{ br: <br /> }} />
                        </Text>
                        <Textarea placeholder={t("pupilPage.confirmDenyModal.denyReasonPlaceholder")} onChange={(e) => (setCancelReason(e.target.value))}/>
                    </ConfirmDenyModal>
                )
            }
            <Tr key={order.id} my=".8rem" pl="0px">
                <Td>{order?.pupil?.username?.username || ""}</Td>
                <Td>{(order?.pupil?.pupilTags || []).filter((pupilTag) => (pupilTag?.type === "HIGH_FIVE")).map((pupilTag) => (pupilTag?.tag)).slice(0, 3).join(", ")}</Td>
                <Td>{order?.pupil?.class?.school?.title || ""}</Td>
                <Td>{order?.pupil?.class?.title || ""}</Td>
                {showClassNumber ? <Td>{order?.pupil?.classNumber || ""}</Td> : null}
                <Td>{order.createdAt && (`${(new Date(order.createdAt)).toLocaleDateString("en-GB")} ${new Date(order.createdAt).toLocaleTimeString("en-GB")}`)}</Td>
                <Td>
                    <Flex justifyContent={"space-evenly"}>
                        <Button
                            hidden={shouldHideCompleteButton}
                            _hover={{ backgroundColor: "green", color: "white"}}
                            onClick={() => (onCompleteShopItemOrder())}
                        >
                            <CheckIcon />
                        </Button>
                        <Button
                            hidden={shouldHideCancelButton}
                            _hover={{ backgroundColor: "red", color: "white" }}
                            onClick={() => (onCancelShopItemOrder())}
                        >
                            <CloseIcon />
                        </Button>
                    </Flex>
                </Td>
            </Tr>
        </>
    );
};