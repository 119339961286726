/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPupilPassportDataQueryVariables = Types.Exact<{
  pupilId: Types.Scalars['String']['input'];
}>;


export type GetPupilPassportDataQuery = { __typename?: 'Query', pupils: Array<{ __typename?: 'Pupil', id: string, classNumber?: number | null, highfiveRegistrationsCount: number, bikeRegistrationsCount: number, username?: { __typename?: 'Username', username: string } | null, class: { __typename?: 'Class', id: string, title: string, school: { __typename?: 'School', id: string, title: string, classNumberEnabled: boolean, schoolyear: { __typename?: 'Schoolyear', project: { __typename?: 'Project', id: string, title: string } } } }, pupilTags?: Array<{ __typename?: 'PupilTag', type: string, tag: string } | null> | null, pupilTotal?: { __typename?: 'PupilTotal', totalCredits: number } | null }> };


export const GetPupilPassportDataDocument = gql`
    query getPupilPassportData($pupilId: String!) {
  pupils(filter: {id: {equals: $pupilId}}) {
    id
    username {
      username
    }
    classNumber
    class {
      id
      title
      school {
        id
        title
        schoolyear {
          project {
            id
            title
          }
        }
        classNumberEnabled
      }
    }
    pupilTags {
      type
      tag
    }
    highfiveRegistrationsCount: moduleRegistrationsCount(
      filter: {type: {equals: "HIGH_FIVE"}, replaced: {equals: false}}
    )
    bikeRegistrationsCount: moduleRegistrationsCount(
      filter: {type: {equals: "BIKE"}, replaced: {equals: false}}
    )
    pupilTotal {
      totalCredits
    }
  }
}
    `;

/**
 * __useGetPupilPassportDataQuery__
 *
 * To run a query within a React component, call `useGetPupilPassportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPupilPassportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPupilPassportDataQuery({
 *   variables: {
 *      pupilId: // value for 'pupilId'
 *   },
 * });
 */
export function useGetPupilPassportDataQuery(baseOptions: Apollo.QueryHookOptions<GetPupilPassportDataQuery, GetPupilPassportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPupilPassportDataQuery, GetPupilPassportDataQueryVariables>(GetPupilPassportDataDocument, options);
      }
export function useGetPupilPassportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPupilPassportDataQuery, GetPupilPassportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPupilPassportDataQuery, GetPupilPassportDataQueryVariables>(GetPupilPassportDataDocument, options);
        }
export type GetPupilPassportDataQueryHookResult = ReturnType<typeof useGetPupilPassportDataQuery>;
export type GetPupilPassportDataLazyQueryHookResult = ReturnType<typeof useGetPupilPassportDataLazyQuery>;
export type GetPupilPassportDataQueryResult = Apollo.QueryResult<GetPupilPassportDataQuery, GetPupilPassportDataQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    