import { Flex, Select, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { ConfirmDenyModal } from "components/modal/confirmDenyModal";
import { useEffect, useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useGetOpenShopItemOrdersForAssignedShopItemQuery } from "../models/getOpenShopItemOrdersForAssignedShopItem";
import { ShopItemOrderHistoryModalTableRow } from "./shopItemOrderHistoryModalTableRow";

interface Props {
    isOpened: boolean;
    onClose: () => void;
    assignedShopItemId: string;
    assignedShopItemTitle: string;
}

export const ShopItemOrderHistoryModal = (props: Props) => {
    const { t } = useTranslation();
    const { isOpened, onClose, assignedShopItemId, assignedShopItemTitle } = props;

    const textColor = useColorModeValue("gray.700", "white");

    const [ schools, setSchools ] = useState<{id: string, title: string}[]>([]);
    const [ classes, setClasses ] = useState<{id: string, title: string}[]>([]);

    const [ schoolFilterValue, setSchoolFilterValue ] = useState("");
    const [ classFilterValue, setClassFilterValue ] = useState("");

    const { data } = useGetOpenShopItemOrdersForAssignedShopItemQuery({ variables: { assignedShopItemId }, skip: !isOpened });

    useEffect(() => {
        if (data && data.shopItemOrders && data.shopItemOrders.length) {
            const schools: {id: string, title: string}[] = [];
            const classes: {id: string, title: string}[] = [];

            for (const order of data.shopItemOrders) {
                if (order?.pupil?.class) {
                    const existingClass = classes.find(c => c.id === order?.pupil?.class.id);

                    if (!existingClass) {
                        classes.push({
                            id: order.pupil.class.id,
                            title: order.pupil.class.title
                        });
                    }

                    if (order.pupil.class?.school) {
                        const existingSchool = schools.find(s => s.id === order?.pupil?.class.school.id);

                        if (!existingSchool) {
                            schools.push({
                                id: order.pupil.class.school.id,
                                title: order.pupil.class.school.title
                            });
                        }
                    }
                }
            }

            setSchools(Array.from(schools));
            setClasses(Array.from(classes));
        }
    }, [ data ]);

    const showClassNumber = useMemo(() => {
        return (data?.shopItemOrders || []).some(sio => sio.pupil?.class?.school?.classNumberEnabled === true);
    }, [data]);

    return (
        <ConfirmDenyModal isOpened={isOpened} title={t("manageStockPage.shopItemOrderHistoryModal.title")} onClose={onClose} size="4xl">
            <Text>
                <Trans i18nKey="manageStockPage.shopItemOrderHistoryModal.description" components={{ strong: <strong /> }} values={{ assignedShopItemTitle }} />
            </Text>
            <Flex marginTop="10px" marginBottom="10px">
                <Select placeholder={t("manageStockPage.shopItemOrderHistoryModal.schoolFilter.placeholder")} margin="10px" onChange={(e) => (setSchoolFilterValue(e.target.value))}>
                    {
                        schools.map((school) => (
                            <option key={school.id} value={school.id}>
                                {school.title}
                            </option>
                        ))
                    }
                </Select>
                <Select placeholder={t("manageStockPage.shopItemOrderHistoryModal.classFilter.placeholder")} margin="10px" onChange={(e) => (setClassFilterValue(e.target.value))}>
                    {
                        classes.map((classRoom) => (
                            <option key={classRoom.id} value={classRoom.id}>
                                {classRoom.title}
                            </option>
                        ))
                    }
                </Select>
            </Flex>
            <Table variant="simple" color={textColor}>
                <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                    {[
                        t("manageStockPage.shopItemOrderHistoryModal.table.username"), 
                        t("manageStockPage.shopItemOrderHistoryModal.table.tagIds"),
                        t("manageStockPage.shopItemOrderHistoryModal.table.school"),
                        t("manageStockPage.shopItemOrderHistoryModal.table.class"),
                        showClassNumber ? t("manageStockPage.shopItemOrderHistoryModal.table.classNumber") : undefined,
                        t("manageStockPage.shopItemOrderHistoryModal.table.requestedAt"),
                        "",
                        ""
                    ].filter(caption => caption !== undefined).map((caption, idx) => {
                        return (
                            <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                                {caption}
                            </Th>
                        );
                    })}
                    </Tr>
                </Thead>
                <Tbody>
                {
                        ((data && data.shopItemOrders) || [])
                            .filter((order) => {
                                if (!order || !order.pupil) {
                                    return false;
                                }

                                if (schoolFilterValue && order.pupil.class?.school?.id!== schoolFilterValue) {
                                    return false;
                                }

                                if (classFilterValue && order.pupil.class?.id!== classFilterValue) {
                                    return false;
                                }

                                return true;
                            })
                            .filter((order) => (!!order))
                            .map((order) => (<ShopItemOrderHistoryModalTableRow order={order} showClassNumber={showClassNumber} />))
                    }
                </Tbody>
            </Table>
        </ConfirmDenyModal>
    );
};
