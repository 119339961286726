/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOpenShopItemOrdersForAssignedShopItemQueryVariables = Types.Exact<{
  assignedShopItemId: Types.Scalars['String']['input'];
}>;


export type GetOpenShopItemOrdersForAssignedShopItemQuery = { __typename?: 'Query', shopItemOrders: Array<{ __typename?: 'ShopItemOrder', id: string, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, shopItem?: { __typename?: 'AssignedShopItem', title?: string | null, image: { __typename?: 'Asset', url: string }, type: { __typename?: 'ShopItemType', type: Types.ShopItemTypeType } } | null, pupil?: { __typename?: 'Pupil', classNumber?: number | null, username?: { __typename?: 'Username', username: string } | null, pupilTags?: Array<{ __typename?: 'PupilTag', tag: string, type: string } | null> | null, class: { __typename?: 'Class', id: string, title: string, school: { __typename?: 'School', id: string, title: string, classNumberEnabled: boolean } } } | null }> };


export const GetOpenShopItemOrdersForAssignedShopItemDocument = gql`
    query getOpenShopItemOrdersForAssignedShopItem($assignedShopItemId: String!) {
  shopItemOrders(
    filter: {deliveredAt: {isNull: true}, canceledAt: {isNull: true}, assignedShopItemId: {equals: $assignedShopItemId}}
    sort: [{field: "createdAt", order: DESC}]
  ) {
    id
    shopItem {
      title
      image {
        url
      }
      type {
        type
      }
    }
    deliveredAt
    canceledAt
    pupil {
      username {
        username
      }
      pupilTags {
        tag
        type
      }
      class {
        id
        title
        school {
          id
          title
          classNumberEnabled
        }
      }
      classNumber
    }
    createdAt
  }
}
    `;

/**
 * __useGetOpenShopItemOrdersForAssignedShopItemQuery__
 *
 * To run a query within a React component, call `useGetOpenShopItemOrdersForAssignedShopItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenShopItemOrdersForAssignedShopItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenShopItemOrdersForAssignedShopItemQuery({
 *   variables: {
 *      assignedShopItemId: // value for 'assignedShopItemId'
 *   },
 * });
 */
export function useGetOpenShopItemOrdersForAssignedShopItemQuery(baseOptions: Apollo.QueryHookOptions<GetOpenShopItemOrdersForAssignedShopItemQuery, GetOpenShopItemOrdersForAssignedShopItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenShopItemOrdersForAssignedShopItemQuery, GetOpenShopItemOrdersForAssignedShopItemQueryVariables>(GetOpenShopItemOrdersForAssignedShopItemDocument, options);
      }
export function useGetOpenShopItemOrdersForAssignedShopItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenShopItemOrdersForAssignedShopItemQuery, GetOpenShopItemOrdersForAssignedShopItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenShopItemOrdersForAssignedShopItemQuery, GetOpenShopItemOrdersForAssignedShopItemQueryVariables>(GetOpenShopItemOrdersForAssignedShopItemDocument, options);
        }
export type GetOpenShopItemOrdersForAssignedShopItemQueryHookResult = ReturnType<typeof useGetOpenShopItemOrdersForAssignedShopItemQuery>;
export type GetOpenShopItemOrdersForAssignedShopItemLazyQueryHookResult = ReturnType<typeof useGetOpenShopItemOrdersForAssignedShopItemLazyQuery>;
export type GetOpenShopItemOrdersForAssignedShopItemQueryResult = Apollo.QueryResult<GetOpenShopItemOrdersForAssignedShopItemQuery, GetOpenShopItemOrdersForAssignedShopItemQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    