import {
    Flex,
    SimpleGrid,
    Skeleton,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { GetPupilPassportDataQuery } from "../models/getPupilPassportData";
import { useTranslation } from "react-i18next";

interface Props {
    getPupilPassportDataResult: GetPupilPassportDataQuery | undefined;
    getPupilPassportDataLoading: boolean;
}

export const PupilPassport = (props: Props) => {
    const { getPupilPassportDataResult, getPupilPassportDataLoading } = props;

    const { t } = useTranslation();
    const textColor = useColorModeValue("gray.700", "white");
    const subTextColor = useColorModeValue("gray.500", "gray.200");

    const result = getPupilPassportDataResult || { pupils: [] };
    const pupils = result.pupils || [];
    const pupil = pupils.length ? result.pupils[0] : null;

    const getPupilData = (loadedElement: ReactNode, skeletonHeight: string = "20px", skeletonWidth?: string) => {
        const width = skeletonWidth || `${Math.random() * (30 - 20) + 20}%`;

        return getPupilPassportDataLoading ?
            <Skeleton width={width} height={skeletonHeight} />
            : loadedElement
    };

    return (
        <>
            <Flex align="center" mb="18px">
                {
                    getPupilData(
                        <Text fontSize="4xl" color={textColor} fontWeight="bold">
                            🦸 {pupil && pupil.username && pupil.username.username}
                        </Text>,
                        "35px",
                        "100%"
                    )
                }
            </Flex>

            <SimpleGrid gridTemplateColumns={"15% 85%"} rowGap={"20px"} columnGap={"20px"}>
                <Text fontSize="md" color={textColor} fontWeight="bold">
                    {t("pupilPage.pupilPassport.municipality")}:
                </Text>
                {
                    getPupilData(
                        <Text fontSize="md" color={subTextColor} fontWeight="400">
                            {pupil?.class.school.schoolyear.project.title}
                        </Text>
                    )

                }

                <Text fontSize="md" color={textColor} fontWeight="bold">
                    {t("pupilPage.pupilPassport.school")}:
                </Text>
                {
                    getPupilData(
                        <Text fontSize="md" color={subTextColor} fontWeight="400">
                            {pupil?.class.school.title}
                        </Text>
                    )
                }

                <Text fontSize="md" color={textColor} fontWeight="bold">
                    {t("pupilPage.pupilPassport.class")}:
                </Text>
                {
                    getPupilData(
                        <Text fontSize="md" color={subTextColor} fontWeight="400">
                            {pupil?.class.title}
                        </Text>
                    )
                }

                {pupil && pupil.class.school.classNumberEnabled ?
                    (<>
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                            {t("pupilPage.pupilPassport.classNumber")}:
                        </Text>
                        {
                            getPupilData(
                                <Text fontSize="md" color={subTextColor} fontWeight="400">
                                    {pupil?.classNumber}
                                </Text>
                            )
                        }
                    </>) : null
                }

                <Text fontSize="md" color={textColor} fontWeight="bold">
                    {t("pupilPage.pupilPassport.walkTags")}:
                </Text>
                {
                    getPupilData(
                        <Text fontSize="md" color={subTextColor} fontWeight="400" fontFamily={"monospace"}>
                            {(pupil?.pupilTags || []).filter((pupilTag) => ((pupilTag || {}).type === "HIGH_FIVE")).map((pupilTag) => ((pupilTag || {}).tag)).join(" | ")}
                        </Text>
                    )
                }

                <Text fontSize="md" color={textColor} fontWeight="bold">
                    {t("pupilPage.pupilPassport.bikeTags")}:
                </Text>
                {
                    getPupilData(
                        <Text fontSize="md" color={subTextColor} fontWeight="400" fontFamily={"monospace"}>
                            {(pupil?.pupilTags || []).filter((pupilTag) => ((pupilTag || {}).type === "BIKE")).map((pupilTag) => ((pupilTag || {}).tag)).join(" | ")}
                        </Text>
                    )
                }
            </SimpleGrid>
        </>
    );
};