import {
    Accordion,
    Container,
    Flex,
    Spinner,
    Stack,
    Text
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useGetShopItemAssignmentsQuery } from "./models/getShopItemAssignements";
import { generateProjectAccordionItems, mapShopItemAssignments } from "./utils/accordionGenerator";
import { useTranslation } from "react-i18next";


export const ManageStockPage = () => {
    const { t } = useTranslation();
    const now = useMemo(() => {
        return new Date();
    }, []);
    const { data, loading: getShopItemAssignmentsLoading } = useGetShopItemAssignmentsQuery({
        variables: {now}
    });
    const [ showLoading, setShowLoading ] = useState(false);
    const [ loadingShown, setLoadingShown ] = useState(false);

    useEffect(() => {
        if (getShopItemAssignmentsLoading && !loadingShown) {
            setLoadingShown(true);
            setShowLoading(true);
        }

        if (!getShopItemAssignmentsLoading) {
            setShowLoading(false);
        }
    }, [ getShopItemAssignmentsLoading ]);

    if (showLoading) {
        return (
            <Container maxW={"7xl"}>
                <Stack spacing={{ base: 6, md: 10 }}>
                    <Flex direction={"row"} justifyContent="center" alignItems="center">
                        <Spinner size="xl" />
                    </Flex>
                </Stack>
            </Container>
        )
    }

    return (
        <Container maxW={"7xl"}>
            <Stack spacing={{ base: 6, md: 10 }}>
                {
                    (data && data.shopItemAssignments && data.shopItemAssignments.length > 0) ?
                        (
                            <Accordion allowMultiple minWidth={"100%"}>
                                {generateProjectAccordionItems(mapShopItemAssignments(data))}
                            </Accordion>
                        ) :
                        (
                            <Flex direction="column" justifyContent="center" alignItems="center">
                                <Text size="xl" >{ t("manageStockPage.noItemsFound") }</Text>
                                <Text size="md" >{ t("contactUs") }</Text>
                            </Flex>
                        )
                }
            </Stack>
        </Container>
    );
};
